export const pfData6 = [
  // {
  //   type: "Cloud VPS V4",
  //   rs: "₹3500",
  //   tx: "Basic features:",
  //   ft: [
  //     "4 vCore CPU",
  //     "4 GB RAM",
  //     "4 User s Pack",
  //     "100 GB Storage",
  //     "Daily BackUp",
  //     "speed upto 100Mbps",
  //     "99.9% uptime",
  //     "24*7 Support",
  //   ],
  // },
  // {
  //   type: "Cloud VPS Premium",
  //   rs: "₹7500",
  //   tx: "Basic features:",
  //   ft: [
  //     "6 vCore CPU",
  //     "8 GB RAM",
  //     "8 User s Pack",
  //     "200 GB Storage",
  //     "Daily BackUp",
  //     "speed upto 100Mbps",
  //     "99.9% uptime",
  //     "24*7 Support",
  //   ],
  // },

  // {
  //   type: "Cloud VPS V8",
  //   rs: "₹15000",
  //   tx: "Basic features:",
  //   ft: [
  //     "8 vCore CPU",
  //     "16 GB RAM",
  //     "15 User s Pack",
  //     "400 GB Storage",
  //     "Daily BackUp",
  //     "speed upto 100Mbps",
  //     "99.9% uptime",
  //     "24*7 Support",
  //   ],
  // },

  {
    server: "GPU CLOUD SERVER",
    type: "Cloud VPS V8",
    rs: "₹7000",
    tx: "Basic features:",
    ft: [
      "8 vCore CPU",
      "4 GB RAM",
      "150 GB Storage",
      "Daily BackUp",
      "speed upto 100Mbps",
      "99.9% uptime",
      "24*7 Support",
    ],
  },
  {
    server: "GPU CLOUD SERVER",
    type: "Cloud VPS V16",
    rs: "₹14000",
    tx: "Basic features:",
    ft: [
      "16 vCore CPU",
      "32GB RAM",
      "200 GB Storage",
      "Daily BackUp",
      "speed upto 100Mbps",
      "99.9% uptime",
      "24*7 Support",
    ],
  },
  {
    server: "GPU CLOUD SERVER",
    type: "Cloud VPS V20",
    rs: "₹24000",
    tx: "Basic features:",
    ft: [
      "20 vCore CPU",
      "64 GB RAM",
      "400 GB Storage",
      "Daily BackUp",
      "speed upto 100Mbps",
      "99.9% uptime",
      "24*7 Support",
    ],
  },
];

export const pfData7 = [
  {
    server: "GPU CLOUD SERVER",
    type: "Starter",
    rs: "₹170",
    xx: "/ Month",
    tx: "Basic features:",
    ft: [
      "1 website",
      "50GB SSD Storage",
      "150 GB Bandwidth",
      "Weekly Backups",
      "Free SSL",
      "Website Security",
      "Email security",
      "GIT Access",
      "2 Databases",
      "DNS Management",
      "Access Manager",
      "2 Subdomains",
      "1 FTP Account",
    ],
  },
  {
    server: "GPU CLOUD SERVER",
    type: "Standard",
    rs: "₹350",
    xx: "/ Month",
    tx: "Basic features:",
    ft: [
      "1 website",
      "100GB SSD Storage",
      "150 GB Bandwidth",
      "Weekly Backups",
      "Free SSL",
      "Website Security",
      "Email security",
      "GIT Access",
      "Unlimited Databases",
      "DNS Management",
      "Access Manager",
      "50 Subdomains",
      "1 FTP Account",
    ],
  },
  {
    server: "GPU CLOUD SERVER",
    type: "Premium",
    rs: "₹800",
    xx: "/ Month",
    tx: "Basic features:",
    ft: [
      "1 website",
      "200GB SSD Storage",
      "150 GB Bandwidth",
      "Weekly Backups",
      "Free SSL",
      "Website Security",
      "Email security",
      "GIT Access",
      "Unlimited Databases",
      "DNS Management",
      "Access Manager",
      "100 Subdomains",
      "15 FTP Account",
    ],
  },
];

export const pfData8 = [
  {
    server: "GPU CLOUD SERVER",
    type: "Stealth",
    rs: "₹300",
    xx: "/ user / month",
    tx: "Basic features:",
    ft: [
      "Silent/Invisible Working",
      "Admin, Managers & User Login",
      "Desktop activity logs",
      "Auto Login/Logout",
      "Working & Idle Hours",
      "Online/Offline Tracking",
      "Application Tracking",
      "URL Tracking",
      "File Name Tracking",

      "Blacklist / Whitelist Apps & URLs",

      "Email Reporting",

      "Department wise report",

      "Meeting App Categorization",

      "Role Base Access",

      "Automated Screenshots",
    ],
  },
  {
    server: "GPU CLOUD SERVER",
    type: "Desklite",
    rs: "₹99",
    xx: "/ user / month",
    tx: "Basic features:",
    ft: [
      "Silent/Invisible Working",
      "Admin Login",
      "Desktop activity logs",
      "Auto Login/Logout",
      "Working & Idle Hours",
      "Online/Offline Tracking",
      "Application Tracking",
    ],
  },
  {
    server: "GPU CLOUD SERVER",
    type: "Tagger",
    rs: "₹450",
    xx: "/ user / month",
    tx: "Basic features:",
    ft: [
      "Silent/Invisible Working",
      "Admin, Managers & User Login",
      "Desktop activity logs",
      "Auto Login/Logout",
      "Working & Idle Hours",
      "Online/Offline Tracking",
      "Application Tracking",
      "URL Tracking",
      "File Name Tracking",

      "Blacklist / Whitelist Apps & URLs",

      "Email Reporting",

      "Department wise report",

      "Meeting App Categorization",

      "Role Base Access",

      "Automated Screenshots",
      "Roster Management",
      "Task Logger",
      "Pause/Resume App Tracking",
    ],
  },
];
